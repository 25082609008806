<template>
  <div>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'debtsolution-accounts-actions-list' }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="[
          currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
          'px-3',
          bgTabsNavs,
        ]"
        >ACCOUNT ACTIONS</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'debtsolution-settlement-offers-list' }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="[
          currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
          'px-3',
          bgTabsNavs,
        ]"
        >SETTLEMENT OFFERS
        <span v-if="S_COUNTER_SETTLEMENT > 0 && currentUser.role_id !== 1" class="ml-2">
          <feather-icon
            icon
            :badge="S_COUNTER_SETTLEMENT > 99 ? '99+' : S_COUNTER_SETTLEMENT"
            badge-classes="badge-danger badge-glow"
          />
        </span>
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-info border-3 border-table-radius px-0"
    >
      <router-view />
    </b-card>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import AccountsActionsService from "@/views/debt-solution/views/accounts-actions/service/actions.service";
export default {
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      skin: "appConfig/skin",
    }),
    ...mapState({
      S_COUNTER_SETTLEMENT: (state) =>
        state.DebtSolutionSettlementStore.S_COUNTER_SETTLEMENT,
    }),
    routeModule() {
      return this.$route.meta.route;
    },
    isLeadsRoute() {
      return this.$route.path === `/${this.routeModule}/accountsactions/`;
    },
  },
  methods: {
    async getCounter() {
      await AccountsActionsService.counterOffer({
        role_id: this.currentUser.role_id,
      });
    },
  },
  created() {
    this.getCounter();
  },
};
</script>
<style lang="">
</style>